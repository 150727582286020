<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title>
        栏目分类
      </template>
      <template #input>
        <a-button class="all_boder_btn" @click="pCode = '', modelForm = {}, visible=true">新建栏目</a-button>
      </template>
    </HeaderBox>

    <a-table
      class="table-template"
      :expandIcon="expandIcon"
      :columns="columns"
      :data-source="data"
      :defaultExpandAllRows="true"
      :pagination="false"
      :rowKey="item=>item.id"
      :loading="loading"
      childrenColumnName="childList"
    >
      <template slot="sort" slot-scope="item,row">
        <a-input-number v-if="row.pid" id="inputNumber" v-model="item" :min="1" @change="onSortChange(item,row)" />
      </template>
    
      <template slot="catalogue" slot-scope="item,row">
        <a @click="editData(row)">编辑</a>
        <span> | </span>
        <a v-if="true" @click="newData(row)">新建子栏目</a>
        <span> | </span>
        <a @click="removeData(row.id)">删除</a>
      </template>
    </a-table>

    <!-- 新建/编辑 -->
    <a-modal v-model="visible" :title="(modelForm.id?'编辑':'新建') + (!!modelForm.pid?'子':'') + '栏目'" :confirm-loading="confirmLoading" @ok="setData()" okText="确认">
      <!-- 根分类 -->
      <template v-if="!modelForm.pid">
        <a-row class="rows">
          <a-col span="5" class="left">栏目名称</a-col>
          <a-col span="19" class="right">
            <a-input placeholder="请输入栏目名称" v-model="modelForm.columnTitle"/>
          </a-col>
        </a-row>
      </template>
      <!-- 子分类 -->
      <template v-else>
        <a-row class="rows">
          <a-col span="5" class="left">当前分类</a-col>
          <a-col span="19" class="right">
            <div style="line-height:32px;">{{levelName}}</div>
          </a-col>
        </a-row>
        <a-row class="rows">
          <a-col span="5" class="left">子栏目名称</a-col>
          <a-col span="19" class="right">
            <a-input placeholder="请输入子栏目名称" v-model="modelForm.columnTitle"/>
          </a-col>
        </a-row>
      </template>
      <a-row class="rows">
        <a-col span="5" class="left">栏目编码</a-col>
        <a-col span="19" class="right" style="display: flex;align-items: center;">
          <span v-if="pCode" style="margin-right: 10px;">{{ pCode }}</span>
          <a-input placeholder="可拼音描述栏目" v-model="modelForm.columnCode"/>
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col span="5" class="unRequired">说明</a-col>
        <a-col span="19" class="right" style="display: flex;align-items: center;">
          <span v-if="pCode" style="margin-right: 10px;">{{ pCode }}</span>
          <a-textarea placeholder="请输入说明" v-model="modelForm.remark"/>
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox.vue";
const columns = [
  {
    title: "栏目",
    dataIndex: "columnTitle",
    key: "columnTitle",
  },
  {
    title: "栏目编码",
    dataIndex: "columnCode",
    key: "columnCode",
  },
  {
    title: "排序",
    align: "center",
    dataIndex: "sort",
    scopedSlots: {
      customRender: "sort",
    },
  },
  {
    title: "栏目操作",
    // align: "center",
    dataIndex: "catalogue",
    width: '200px',
    key: "catalogue",
    scopedSlots: { customRender: "catalogue" },
  },
];

export default {
  // 可用组件的哈希表
  components: { HeaderBox }, // 接收传值
  props: {}, // 数据对象
  data() {
    return {
      modelForm: {

      },
      pCode: '',
      levelName: '',
      visible: false,
      data: [],
      columns,
      loading: false,
      confirmLoading: false
    };
  }, // 事件处理器
  methods: {
    // 修改位置
    onSortChange(e,item) {
      item.sort = e;
      if(this.timer) {
        clearTimeout(this.timer)
      }
      let _this = this;
      this.timer = setTimeout(()=>{
        this.timer = null;
        _this
          .$ajax({
            url: "/hxclass-management/ArticleColumn/update",
            method: "post",
            params: item
          })
          .then((res) => {
            if (res.code == 200 && res.success) {
              _this.$message.success("操作成功");
              // _this.getList();
            }
          });
      },600)
    },

    expandIcon(props) {
      if((props.record.childList && props.record.childList.length > 0) || props.record.subset > 0){
        if (props.expanded) {
          //有数据-展开时候图标
          return (
            <a
              style="color: 'black',margin-right:0px"
              onClick={(e) => {
                props.onExpand(props.record, e);
              }}
            >
              <a-icon slot="suffixIcon" type="caret-down" />{" "}
            </a>
          );
        } else {
          //有数据-未展开时候图标
          return (
            <a
              style="color: 'black' ,margin-right:0px"
              onClick={(e) => {
                props.onExpand(props.record, e);
                this.getList(props.record.id, props.record)
              }}
            >
              <a-icon slot="suffixIcon" type="caret-right" />
            </a>
          );
        }
      }else{
        return <span style='margin-right:8'></span>
      }
    },

    /**
     * 查询列表数据
     * @param {*} id 
     * @param {*} item - 通过指针指向修改对象中列表数据
     * @param {*} flag - true：新建刷新分类  false/不传：获取列表
     */
    getList(id, item, flag){
      if(!flag && item && item.childList && item.childList.length) return
      this.loading = true
      this.$ajax({
        url: '/hxclass-management/ArticleColumn/list/' + id
      }).then(res=>{
        if(res.code == 200 && res.success){
          if(id != 0) {
            this.$set(item,'childList',res.data)
          }else{
            this.data = res.data
          }
        }
        this.loading = false
        this.levelName = ''
      }).catch(err=>{
        this.loading = false
        this.levelName = ''
      })
    },
    
    // 打开新建分类弹框
    newData(row) {
      this.pCode = row.columnCode + '-'
      this.modelForm = {
        pid: row.id
      }
      this.levelName = row.columnTitle
      this.visible=true;
    },
    
    // 打开编辑分类弹框
    editData(row) {
      this.pCode = '';
      this.modelForm = {
        id: row.id,
        columnTitle: row.columnTitle,
        columnCode: row.columnCode,
        remark: row.remark
      }
      this.visible=true;
    },

    // 修改分类
    setData(){
      if(!this.modelForm.columnTitle){
        if(!this.modelForm.pid){
          this.$message.error('请输入分类名称')
        }else{
          this.$message.error('请输入子分类名称')
        }
        return false
      }
      if(!this.modelForm.columnCode){
        if(!this.modelForm.pid){
          this.$message.error('请输入分类编码')
        }else{
          this.$message.error('请输入子分类编码')
        }
        return false
      }
      // code = 父级code - 子集code
      let params = JSON.parse(JSON.stringify(this.modelForm))
      params.columnCode = this.pCode + this.modelForm.columnCode;
      this.$ajax({
        url: '/hxclass-management/ArticleColumn/update',
        method: 'post',
        params
      }).then(res=>{
        if(res.code == 200 && res.success){
          // 新建
          if(!this.modelForm.id) {
            // 一级栏目
            if(!this.modelForm.pid) {
              this.getList(0)
            }else{
              // 非一级栏目
              this.findItem(this.data, this.modelForm.pid)
            }
          }
          // 编辑
          else{
            let _this = this;
            // 递归查找id对应数据直接修改值
            function mapList(arr, id) {
              arr.some(item=>{
                if(item.id == id) {
                  item.columnTitle = _this.modelForm.columnTitle
                  item.columnCode = _this.modelForm.columnCode
                  item.remark = _this.modelForm.remark
                  return true;
                }
                if(item.childList) {
                  mapList(item.childList, id)
                }
                return false;
              })
            }
            mapList(this.data,this.modelForm.id)
          }
          this.visible = false
        }else{
          this.$message.error(res.message)
        }
      })
    },

    // 循环查谁的子集
    findItem(arr, pid) {
      arr.some(item=>{
        if(item.id == pid) {
          this.getList(pid, item, true)
          return true;
        }
        if(item.childList) {
          this.findItem(item.childList, pid)
        }
        return false;
      })
    },

    // 删除分类
    removeData(e){
      this.$confirm({
        title: '确定删除该标签吗?',
        okText: '确认',
        onOk:()=> {
          this.$ajax({
            url: '/hxclass-management/ArticleColumn/delete/'+e,
            method: 'delete'
          }).then(res=>{
            if(res.code == 200 && res.success){
              let _this = this;
              // 递归查找id对应数据直接修改值
              function mapList(arr, id) {
                arr.some((item,index)=>{
                  if(item.id == id) {
                    arr.splice(index,1)
                    _this.$message.success('删除成功！')
                    return true;
                  }
                  if(item.childList) {
                    mapList(item.childList, id)
                  }
                  return false;
                })
              }
              mapList(this.data,e)
            }else{
              this.$message.error(res.message)
            }
          })
        }
      });
    }
  }, // 生命周期-实例创建完成后调用
  created() {
    this.getList(0)
  }, // 生命周期-实例挂载后调用
  mounted() {}, // 生命周期-实例销毁离开后调用
  destroyed() {}, // 计算属性监听
  computed: {}, // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.all_main_wrap{
  min-height: 100%;
  .btn{
    margin: 0 24px 14px 0;
  }
  a{
    color: @theme;
  }
}
.rows{
  margin-bottom: 20px;
  &:last-child{
    margin-bottom: 0;
  }
  .left{
    text-align: right;
    height: 32px;
    line-height: 32px;
    &::before{
      content: '*';
      color: red;
      margin-right: 2px;
    }
    &::after{
      content: '：';
    }
  }
  .unRequired{
    text-align: right;
    height: 32px;
    line-height: 32px;
    &::after{
      content: '：';
    }
  }
  .flex{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 32px;
    input{
      flex: 1;
    }
    a{
      margin-left: 8px;
    }
  }
}
</style>
